import styled from 'styled-components';
import { font, breakpoint } from 'styles/globals';
import { getIcon } from 'styles/icons';
import { Theme, getColor } from 'styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.list-page {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 9999;
  }
`;

export const Dropdown = styled.div<{ theme: Theme }>`
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  background-color: ${getColor('surfaceWhite')};
  z-index: -5;
  bottom: 0;
  transition: height 0.3s;
  overflow: hidden;

  &.show {
    height: 260px;
  }

  &.article-redesign {
    animation: slide-in 0.5s both;
    @media (${breakpoint.mdMax}) {
      right: -80px;
    }

    &::before {
      display: block;
      content: '';
      height: 33px;
    }
  }
 
`;

export const Overlay = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.24); /* Semi-transparent black */
  transition: opacity 0.3s ease-in;
  z-index: -1;

  z-index: ${({ isVisible }) => (isVisible ? '9999' : '-1')};
  opacity:  ${({ isVisible }) => (isVisible ? '1' : '0')};
`;

export const DropdownContainer = styled.div<{ theme: Theme }>`
  position: relative;
  max-width: 50px;
  text-align: center;
  height: 70px;

  &.article-redesign {
    width: 40px;
    height: 47px;
    transition: all 0.3s ease-in;

    .share {
      &::before {
        margin: 8px;
      }
    }
    
  }

  &.list-page { 
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: ${getColor('shadeSemanticError900')};
    .share {
      &::before {
        background-color: ${getColor('neutralWhite')};
      }
      &:hover::before {
        background-color: ${getColor('neutralWhite')};
      }
    }
    .close {
      opacity: 1;
      &::before {
        margin-top: 12px;
        margin-left: 12px;
      }
    }
  }

  &.without-margins {
      .share {
        &::before {
          margin: 0px !important;
        }
      }
  }
  
  &:not(.article-redesign) {
    .share {
      &::before {
        width: 100%;
      }
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;

    &.share-with-label {
      height: 50%;
    }


    &::before {
      position: absolute;
      ${getIcon('share-icon-2')};
      background-color: ${getColor('iconPrimary')};
      opacity: 0;
    }

    &::after {
      position: absolute;
      ${getIcon('close-icon', { height: 18, width: 18 })};
      background-color: ${getColor('neutralWhite')};
      opacity: 0;
    }
  }

  .share {
    &::before {
      opacity: 1;
      transition: opacity 0.3s ease-out;
    }
  }

  .close {
    &::after {
     opacity: 1;
     transition: opacity 0.3s ease-out;
    }
  }

  .title {
    font-family: ${font.graphikCond};
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: -0.7px;
    color: ${getColor('textLink')};

    @media (${breakpoint.mmMax}) {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
    }
  }

  // TODO: Remove and apply styles in their respective divs below once Article
  // Redesign is done.
  // Style overrides for divs below to fit Article Redesign in a cleaner way
  &.article-redesign,
  &.crash-course {
    div > div > .social-links-container {
      padding: 8px !important;
    }

    a {
      width: 24px !important;
      height: 24px !important;
    }
    .show {
      min-width: 160px;
      right: 0px;
      left: unset;
      overflow: visible;
      top: 45px;
      z-index: 2;
      height: 48px;
      background: transparent;
      border-radius: 4px;
      @media (${breakpoint.lgMin}) {
        top: 33px;
      }
    }
  }

  &.crash-course {
    .show {
      top: 60px;
    }
    }
  }



  @media (${breakpoint.mdMin}) {
    &:hover, &:focus-within {
      & > ${Dropdown} {
        display: block;
        width: 41px;
        height: 180px;
        top: 16px;
        z-index: 1;
        overflow: visible;
        background: transparent;
        transition: all 0.3s ease-out;

        &.crash-course {
          top: 70px;
          width: 50px;
        }
      }
    }
  }
`;

export const DropdownContent = styled.div<{ theme: Theme }>`
  display: flex;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${getColor('borderSecondary')};
  background-color: ${getColor('surfaceWhite')};

  &.list-page-social {
    border: none;
    > .social-links-container {
      flex-direction: column;
      height: auto;
      align-items: flex-start;
      gap: 24px;
      padding-top: 16px;

      & > li {
      margin-right: 0px;
    }
    }
  }

  .social-links-container {
    border-right: none;
    position: initial;
    padding: 16px;
    
    & > div:not(:last-child) {
      margin-bottom: 18px;
    }

    a {
      &:before {
        background-color: ${getColor('iconPrimary')};
      }
    }

    @media (${breakpoint.mdMax}) {
      border-bottom: none;
      height: 40px;
      padding: 8px;

      & > div:not(:last-child) {
        margin-bottom: 0;
        margin-right: 16px;
      }

      a {
        display: inline-block;
        width: 24px;
        height: 24px;
      }
    }
  }
`;
