import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme, getBodyStyle } from 'styles/theme';
import Heading from 'styles/typography/Heading';

export const HeroAndImageWrapper = styled.div`
  width: 100%;

  @media (${breakpoint.xlMin}) {
    display: flex;
    flex-direction: row;
    gap: 48px;
  }

  & > div:only-child {
    margin: 0 auto;
    padding: 0 16px;

    @media (${breakpoint.mdMin}) {
      max-width: 719px;
      padding: 0;
    }

    @media (${breakpoint.xlMin}) {
      max-width: 745px;
    }
  }
`;

export const RedesignedHeroWrapper = styled.div`
  margin: 24px 16px;

  @media (${breakpoint.mdMin}) {
    margin: 24px;
  }

  @media (${breakpoint.xlMin}) {
    width: 100%;
  }

  @media (${breakpoint.xxlMin}) {
    margin: 0px 0px 0px auto;
    width: calc(((100% / 12) * 10) - 8px);
  }
`;

export const RedesignedHeroTitle = styled(Heading)`
  margin: 24px 0;
`;

export const RedesignedHeroImageContainer = styled.div<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'small' }, 'semiBold')};

  @media (${breakpoint.mdMin}) {
    margin: 0 -24px;
  }

  @media (${breakpoint.xlMin}) {
    width: 100%;
    margin: 0;
  }

  .article-video {
    bottom: 32px;
  }

  /* placeholder for image */

  span {
    width: 100%;
  }

  /* placeholder for caption */

`;
