import Link from 'components/Globals/Base/Link/LinkStyles';
import Heading from 'styles/typography/Heading';
import * as S from './SurveyStyles';

const Survey = () => (
  <S.Container>
    <S.Content>
      <Heading
        as='h2'
        $fontFamily='saol'
        $size={{ default: 'level4' }}
      >
        Your Opinion Matters!
      </Heading>
      <S.Description>Please take a moment to share your feedback—we’re always looking to improve.</S.Description>
    </S.Content>
    <Link
      $type='accentButton'
      href='https://fortune.iad1.qualtrics.com/jfe/form/SV_1O1W2H8EkhkSPSC'
      className='btn'
      target='_blank'
    >
      TAKE SURVEY
    </Link>
  </S.Container>
);

export default Survey;
