import * as React from 'react';
import ArticleHeroEyebrow from 'components/ArticleHeroEyebrow';
import { ARTICLE_PAGE_TEST_IDS } from 'constants/testsIds/articlePage';
import { SharedVariablesForTagAndSection } from 'interfaces/components/SharedVariablesForTagAndSection';
import { MediaItem } from 'interfaces/content/Media';
import parseCaptionWhenAltTextEmpty from 'utils/parsingUtils/clientSideParsing';
import useBreakpoint from 'hooks/useBreakpoint';
import { Fortune500Bubble } from 'public/Fortune500Bubble';
import * as S from './HeaderStyles';

export interface FeatureHeaderProps {
  bubblePresent: boolean;
  dek: string;
  displayDek: boolean;
  headerColor: 'white' | 'black';
  image: MediaItem;
  primarySection: SharedVariablesForTagAndSection;
  primaryPostTag: SharedVariablesForTagAndSection;
  title: string;
}

const FeatureHeader: React.FC<FeatureHeaderProps> = ({
  bubblePresent,
  dek,
  displayDek,
  headerColor,
  image,
  primarySection,
  primaryPostTag,
  title,
}) => {
  const isDesktop = useBreakpoint('lgMin');
  const trackingData = {
    contentPlacementCD: 'qa_article_eyebrow',
    eventAction: 'header click',
    eventCategory: 'navigation',
  };

  return (
    <>
      <S.Wrapper>
        <S.ImageContainer>
          <S.Image
            src={image.mediaItemUrl}
            data-cy={ARTICLE_PAGE_TEST_IDS.IMAGE}
            alt={parseCaptionWhenAltTextEmpty(image)}
          />
        </S.ImageContainer>
        <S.TitleWrapper
          headerColor={headerColor}
          hasBubble={bubblePresent}
        >
          {bubblePresent && (
            <S.Bubble>
              <Fortune500Bubble />
            </S.Bubble>
          )}
          <ArticleHeroEyebrow
            primarySection={primarySection}
            primaryPostTag={primaryPostTag}
            trackingData={trackingData}
          />
          <S.Title headerColor={headerColor}>{title}</S.Title>
          {displayDek && dek && isDesktop && <S.Dek headerColor={headerColor}>{dek}</S.Dek>}
        </S.TitleWrapper>
      </S.Wrapper>
      <S.ImageInformation>
        {image.caption && <S.Caption>{image.caption}</S.Caption>}
        {image.credit && <S.Credit>{image.credit}</S.Credit>}
      </S.ImageInformation>
    </>
  );
};

export { FeatureHeader };
