import styled from 'styled-components';
import { getColor, Theme } from 'styles/theme';

export const AuthorsBioCards = styled.div<{ theme: Theme }>`
  margin: 48px 0;
 
  h4 {
    margin-top: 8px;
    margin-bottom: 0;
    padding-bottom: 24px;
    border-bottom: 4px solid ${getColor('borderBlack')};
  }

  .author-bio {
    ul {
      list-style-type: none;
      padding: 0;
    }
  }

  svg {
    fill: ${getColor('neutralBlack')};
    }
`;

export default AuthorsBioCards;
