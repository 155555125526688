import { useState } from 'react';
import SocialLinks from 'components/SocialLinks';
import useBreakpoint from 'hooks/useBreakpoint';
import { EventData } from 'services/Gtm/entities';
import getEarningReportPageTestId from 'constants/testsIds/earningsReportPage';
import * as S from './SocialShareDropdownStyles';

interface Props {
  title: string;
  isArticleRedesign?: boolean;
  withoutMargins?: boolean;
  trackingData?: EventData;
  className?: string;
  isListPage?: boolean;
}

const SocialShareDropdown = ({
  className,
  title,
  isArticleRedesign = false,
  withoutMargins = false,
  trackingData,
  isListPage,
}: Props) => {
  const [showContent, setShowContent] = useState(false);
  const isMobile = useBreakpoint('mdMax');

  const onClickHandler = () => {
    setShowContent((sC) => !sC);
  };

  const closeDropdown = () => {
    setShowContent(false);
  };

  let DropdownClassName = '';
  if (isMobile && showContent) {
    DropdownClassName = isListPage ? 'list-page show' : 'show';
  } else if (isArticleRedesign) {
    DropdownClassName = 'article-redesign';
  } else if (!isArticleRedesign && !isListPage) {
    DropdownClassName = 'crash-course';
  }

  return (
    <>
      {isListPage && (
        <S.Overlay
          data-cy={getEarningReportPageTestId('OVERLAY')}
          isVisible={showContent}
          onClick={closeDropdown}
        />
      )}
      <S.Wrapper className={isListPage ? 'list-page' : ''}>
        <S.DropdownContainer
          className={`${!isArticleRedesign && !isListPage ? 'crash-course' : ''} ${
            isArticleRedesign ? 'article-redesign' : ''
          } ${withoutMargins ? 'without-margins' : ''} ${isListPage ? 'list-page' : ''}`}
        >
          <div
            data-cy={getEarningReportPageTestId('SHARE_ICON')}
            role='button'
            className={showContent && isListPage ? 'icon close' : `icon share ${className}`}
            tabIndex={0}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            aria-label='share-button'
          />
          {!isArticleRedesign && !isListPage ? <p className='title'>Share</p> : null}
          <S.Dropdown className={DropdownClassName}>
            <S.DropdownContent className={isListPage ? 'list-page-social' : ''}>
              <SocialLinks
                title={title}
                isListPage={isListPage}
                className='social-links-container'
                trackingData={trackingData}
              />
            </S.DropdownContent>
          </S.Dropdown>
        </S.DropdownContainer>
      </S.Wrapper>
    </>
  );
};

export default SocialShareDropdown;
