import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getBodyStyle, getColor, Theme } from 'styles/theme';

export const Wrapper = styled.div`
  @media (${breakpoint.mdMin}) {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const ImageContainer = styled.div<{ theme: Theme }>`
  @media (${breakpoint.mdMin}) {
    flex: 1;
  }
`;

export const Image = styled.img<{ theme: Theme }>`
  width: 100%;
`;

export const TitleWrapper = styled.div<{ theme: Theme; headerColor: 'white' | 'black'; hasBubble: boolean }>`
  background: ${(props) => (props.headerColor === 'black' ? getColor('surfaceBlack') : getColor('neutralWhite'))};
  display: flex;
  padding: ${(props) => (props.hasBubble ? '64px 20px 24px' : '40px 20px 24px')};
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;

  @media (${breakpoint.mdMax}) {
    position: relative;
  }

  @media (${breakpoint.mdMin}) {
    justify-content: center;
    width: 50%;
    padding: 0 68px;
    border-bottom: 1px solid ${(props) =>
    (props.headerColor === 'white' ? getColor('neutralLightGray3') : 'transparent')};
  }

  @media (${breakpoint.lgMin}) {
    padding: 0 68px;
  }
`;

export const Bubble = styled.div`
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);

  @media (${breakpoint.mdMin}) {
    top: unset;
    transform: translate(-50%, 0);
  }
`;

export const Title = styled.div<{ theme: Theme; headerColor: 'white' | 'black' }>`
  ${getBodyStyle('graphikCond', { default: 'xxlarge' }, 'semiBold')};
  color: ${(props) => (props.headerColor === 'black' ? getColor('neutralWhite') : getColor('surfaceBlack'))};
  text-align: center;
  font-size: 40px;
  line-height: 120%;

  @media (${breakpoint.hybridMin}) {
    font-size: 60px;
  }
`;

export const Dek = styled.div<{ theme: Theme; headerColor: 'white' | 'black' }>`
  ${getBodyStyle('graphikCond', { default: 'medium' })};
  color: ${(props) => (props.headerColor === 'black' ? getColor('neutralWhite') : getColor('surfaceBlack'))};
  text-align: center;
`;

export const ImageInformation = styled.div`
  margin: -40px 20px 0;

  @media (${breakpoint.mdMin}) {
    margin: -40px 0 0;    
  }
`;

export const Caption = styled.div<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'small' }, 'semiBold')};
`;

export const Credit = styled.div<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'xsmall' }, 'light')};
  color: ${getColor('neutralDarkGray')};
`;
