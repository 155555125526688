import styled from 'styled-components';
import { breakpoint } from 'styles/globals';

export const VideoContainer = styled.div`
    min-height: 242px;
    @media (${breakpoint.mdMin}) {
        min-height: 513px;
    }
    .stn-video-player {
        margin: 0;
    }
`;

export default VideoContainer;
