import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getBodyStyle, getColor, Theme } from 'styles/theme';

export const Container = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  max-width: 745px;
  border: 1px solid ${getColor('borderTertiary')};
  margin: 0 16px;
  position: relative;
  z-index: 1001;
  margin-bottom: -24px;

  @media (${breakpoint.mdMin}) {
    flex-direction: row;
    width: 704px;
    margin: -24px auto;
  }

  @media (${breakpoint.xlMin}) {
    width: 745px;
  }
  
  .btn {
    font-size: 16px;

    @media (${breakpoint.mdMax}) {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-bottom: 20px;

  @media (${breakpoint.mdMin}) {
    max-width: 80%;
    margin-bottom: 0;
  }
`;

export const Description = styled.p<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'default' }, 'regular')}
`;
