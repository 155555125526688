/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

export const Fortune500Bubble = () => (
  <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' id='Layer_1' version='1.1' viewBox='0 0 500 500'>
    <defs>
      <clipPath id='clippath'>
        <rect className='st0' style={{ fill: 'none' }} width='500' height='500'/>
      </clipPath>
    </defs>
    <g className='st3' style={{ clipPath: 'url(#clippath)' }}>
      <path className='st1' style={{ fill: '#ed1c24' }} d='M250,500c138.1,0,250-111.9,250-250S388.1,0,250,0,0,111.9,0,250s111.9,250,250,250'/>
      <path className='st2' style={{ fill: '#fff' }} d='M256.6,172.2v155.7c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7v-155.7c0-3.7,3-6.7,6.7-6.7s6.7,3,6.7,6.7ZM298.5,327.8v-155.7c0-25.6-21.8-46.4-48.6-46.4s-48.6,20.8-48.6,46.4v155.7c0,25.6,21.8,46.4,48.6,46.4s48.6-20.8,48.6-46.4'/>
      <path className='st2' style={{ fill: '#fff' }} d='M190.9,327.8h0v-79.3c0-25.6-21.8-46.4-48.6-46.4h-6.7v-29.9h50.9v-41.9h-92.8v113.8h48.6c3.7,0,6.7,3,6.7,6.7v77.1c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7v-70.4h-41.9v70.4c0,25.6,21.8,46.4,48.6,46.4s48.6-20.8,48.6-46.4'/>
      <path className='st2' style={{ fill: '#fff' }} d='M364.5,172.2h0v155.7-45.9,45.9c0,3.7-3,6.7-6.7,6.7s-5.3-1.8-6.3-4.3c-.1-.4-.3-.7-.3-1.1,0-.2-.1-1.1-.1-1.4,0,.2,0-.2,0,0v-155.7c0-3.7,3-6.7,6.7-6.7s6.7,3,6.7,6.7M406.4,257.5v-85.3c0-25.6-21.8-46.4-48.6-46.4s-48.6,20.8-48.6,46.4v155.7c0,4.8.8,9.4,2.2,13.8.7,2.1,1.5,4.1,2.5,6.1,0,0,0,.2.1.2,7.9,15.6,24.5,26.3,43.9,26.3s48.6-20.8,48.6-46.4v-70.4Z'/>
    </g>
  </svg>
);