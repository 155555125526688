import styled, { css } from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getBodyStyle, getColor, Theme } from 'styles/theme';

export const BylineAndShareWrapper = styled.div<{ isFeatureArticle: boolean }>`
  display: flex;
  flex-direction: row;

  ${(props) =>
    props.isFeatureArticle &&
    css`
      padding: 0 16px;
      justify-content: space-between;

      @media (${breakpoint.mdMin}) {
        padding: 0;
        min-width: 700px;
        margin: 0 auto 16px;
      }
    `}
`;

export const RedesignedHeroBylineWrapper = styled.div<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'small' })};
  letter-spacing: 1.2px;
  margin-right: 16px;
  min-width: 210px;
`;

export const RedesignedHeroAuthorsContainer = styled.div`
  display: flex;
  text-transform: uppercase;
`;

export const RedesignedHeroDate = styled.div<{ theme: Theme }>`
  color: ${getColor('textSecondary')};
  letter-spacing: 0;
  min-height: 21px;
`;
