import dynamic from 'next/dynamic';
import Heading from 'styles/typography/Heading';
import { ARTICLE_PAGE_TEST_IDS } from 'constants/testsIds/articlePage';
import { AuthorBylineConfig } from 'interfaces/content/AuthorByline';
import { Author } from 'interfaces/content/Authors';
import * as S from './AboutTheContributorsStyles';

const AuthorBio = dynamic(() => import('components/AuthorBio'), {
  ssr: false,
});

const AboutTheContributors = ({ authors, reviewedBy }: AuthorBylineConfig) => (
  <S.AuthorsBioCards data-cy={ARTICLE_PAGE_TEST_IDS.AUTHORS_BIO_CARDS}>
    <Heading
      as='h4'
      $fontFamily='graphikCond'
      $size={{ default: 'level3' }}
      data-cy={ARTICLE_PAGE_TEST_IDS.AUTHORS_BIO_SECTION_TITLE}
    >
      About the contributors
    </Heading>
    {authors.map((author) => (
      <AuthorBio
        key={author.email}
        className='author-bio'
        author={author}
      />
    ))}
    {reviewedBy && (
      <AuthorBio
        key={reviewedBy.uri}
        className='author-bio'
        author={reviewedBy as Author}
      />
    )}
  </S.AuthorsBioCards>
);

export default AboutTheContributors;
