import React from 'react';
import ArticleHeroEyebrow from 'components/ArticleHeroEyebrow';
import { HeroConfig } from 'interfaces/content/articles/Articles';
import FeaturedHeroMedia from 'components/FeaturedHeroMedia';
import getArticlePageTestId, { ARTICLE_PAGE_TEST_IDS } from 'constants/testsIds/articlePage';
import FeatureByline from 'components/FeatureByline';
import * as S from './heroStyles';

const Hero: React.FC<HeroConfig> = ({ article }) => {
  const { featuredMediaType, imageCaption, imageCredit, postSettings, videoId, title } = article;

  const primarySection = {
    link: article.primarySection.uri,
    name: article.primarySection.name,
  };

  const primaryPostTag = {
    link: article.primaryPostTag.uri,
    name: article.primaryPostTag.name,
  };

  const trackingData = {
    contentPlacementCD: 'article side by side hero',
    eventAction: 'hero click',
    eventCategory: 'navigation',
  };

  const hasImageOrVideo = featuredMediaType || videoId;

  return (
    <S.HeroAndImageWrapper data-cy={ARTICLE_PAGE_TEST_IDS.WRAPPER}>
      <S.RedesignedHeroWrapper data-cy={getArticlePageTestId('EYEBROW')}>
        <ArticleHeroEyebrow
          primarySection={primarySection}
          primaryPostTag={primaryPostTag}
          className='eyebrow'
          trackingData={trackingData}
        />
        <S.RedesignedHeroTitle
          data-cy={getArticlePageTestId('TITLE')}
          as='h1'
          $fontFamily='saol'
          $size={{ default: 'level3', mdMin: 'large' }}
        >
          {title}
        </S.RedesignedHeroTitle>
        <FeatureByline article={article} />
      </S.RedesignedHeroWrapper>
      {hasImageOrVideo && (
        <S.RedesignedHeroImageContainer data-cy={getArticlePageTestId('IMAGE')}>
          <FeaturedHeroMedia
            className='article-video'
            featuredMediaType={featuredMediaType}
            imageCaption={imageCaption}
            imageCredit={imageCredit}
            postSettings={postSettings}
            videoId={videoId}
          />
        </S.RedesignedHeroImageContainer>
      )}
    </S.HeroAndImageWrapper>
  );
};

export default Hero;
