import React from 'react';
import useDateTime from 'hooks/useDateTime';
import BylineList from 'components/Byline';
import SocialShareDropdown from 'components/SocialShareDropdown';
import getArticlePageTestId from 'constants/testsIds/articlePage';
import { HeroConfig } from 'interfaces/content/articles/Articles';
import * as S from './FeatureBylineStyles';

export const FeatureByline: React.FC<HeroConfig> = ({ article, isFeatureArticle = false }) => {
  const { authors, dateGmt, modifiedGmt, showModifiedTimestamp } = article;

  const localDate = useDateTime(dateGmt);
  const updatedDate = useDateTime(modifiedGmt);
  const isUpdated = !(localDate === updatedDate);

  const trackingData = {
    contentPlacementCD: 'article_byline',
    eventAction: 'byline click',
    eventCategory: 'navigation',
  };

  const socialShareTrackingData = {
    contentPlacementCD: 'article_byline',
    eventAction: 'Social Share -',
    eventCategory: 'Social',
  };

  return (
    <S.BylineAndShareWrapper isFeatureArticle={isFeatureArticle}>
      <S.RedesignedHeroBylineWrapper data-cy={getArticlePageTestId('AUTHOR_AND_DATE')}>
        <S.RedesignedHeroAuthorsContainer>
          <BylineList
            data-cy={getArticlePageTestId('AUTHORS')}
            bylineList={authors}
            isArticle
            description={article.postSettings?.bylineCommentary}
            className='article-info'
            trackingData={{ ...trackingData, eventLabel: 'author click' }}
          />
        </S.RedesignedHeroAuthorsContainer>
        <S.RedesignedHeroDate>{localDate}</S.RedesignedHeroDate>
        {showModifiedTimestamp && isUpdated ? <S.RedesignedHeroDate>Updated {updatedDate}</S.RedesignedHeroDate> : null}
      </S.RedesignedHeroBylineWrapper>
      <SocialShareDropdown
        title={article.title}
        isArticleRedesign
        trackingData={socialShareTrackingData}
      />
    </S.BylineAndShareWrapper>
  );
};

export default FeatureByline;
